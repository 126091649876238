<template>
  <div class="camera-transform-option" :class="className"></div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: { option: { type: String, required: true } }
})
export default class CameraTransformOption extends Vue {
  get className() {
    return `camera-transform-option_${this.option}`;
  }
}
</script>

<style lang="stylus">
.camera-transform-option {
  font-size: 1.75rem;
  text-align: center;

  &::before {
    content: 'F';
    display: inline-block;
  }

  &_flip_horizontally::before {
    transform: scaleX(-1);
  }

  &_rotate_180::before {
    transform: rotate(180deg);
  }

  &_flip_vertically::before {
    transform: scaleY(-1);
  }

  &_transpose::before {
    transform: scaleX(-1) rotate(90deg);
  }

  &_rotate_90::before {
    transform: rotate(-90deg);
  }

  &_transverse::before {
    transform: rotate(90deg) scaleX(-1);
  }

  &_rotate_270::before {
    transform: rotate(90deg);
  }
}
</style>
